@import "../../global.scss";

.portfolioList {
  font-size: 14px;
  margin: 0px 25px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;
  color: $C-2;

  @include medium {
    font-size: 14px;
    overflow: hidden;
    padding: 4px;
    border-radius: 5px;
  }

  @include mobile {
    margin: 0px 15px;
    padding: 7px;
  }

  &.active {
    background-color: $C-1;
    color: $C-3;
  }
}
