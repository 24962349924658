@import "../../global.scss";

.container {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  

  .project {
    width: 80vw;
    height: 100%;
    background-color: $C-4;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      border-radius: 0;
      width: 100%;
      flex-direction: column;
    }

    .left {
      flex: 5;
      height: 80%;
      overflow: hidden;

      .leftContainer{
        padding: 20px;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: center;

        @include mobile {
          padding: 0;
        }

        .imgContainer{
          flex: 7;
          // width: 100%;
          // height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          img {
            height: 100%;
          }
        } 


        .languages {
          flex: 4;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h3 {
            color: $C-1;
            margin-bottom: 10px;
            text-align: center;

            @include medium {
              display: none;
              
            }
          }

          .languageIcons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            svg {
              height: 60px;
              padding: 10px;

              @include mobile {
                height: 30px;
                padding: 7px;
              }
            }
          }
        }
      }
    }

    .right {
      flex: 5;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $C-2;

      @include mobile {
        flex: 7;
        justify-content: start;
      }

      h1 {
        color: $C-1;
        margin-top: 30px;
        font-size: 50px;
        text-align: center;
        @include medium {
          margin-top: 20px;
          font-size: 40px;
          overflow: hidden;
        }
        @include mobile {
          margin: 0;
          font-size: 25px;
          
        }
      }

      .rightContainer {
        padding: 30px;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include medium {
          height: 80%;
        }
        @include mobile {
          padding: 5px 10px;
        }

        p {
          color: $C-1;
          @include medium {
            font-size: 14px;
          }
          @include mobile {
            font-size: 12px;
          }
        }
        .bullets {              
          ul {
            li {
              display: flex;
              align-items: center;
              
              margin-left: 10px;
              margin-bottom: 10px;
              @include mobile {
                margin: 5px;
                font-size: 12px;
              }
              .check {
                font-size: 30px;
                font-weight: 500;
                color: $C-5;
                margin-right: 5px;
                @include mobile {
                  font-size: 20px;
                }
              }
            }
          }
        }
        

        .linksContainer {
          display: flex;
          align-items: center;
          justify-content: center;


          a {
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 8px;
            padding: 5px 10px;
            margin: 10px;
            width: 100px;
            height: 70px;
            background-color: $C-1;
            color: $C-4;
            overflow: hidden;
            font-size: 14px;
            white-space: nowrap;
            text-decoration: none;
            transition: all 0.5s ease;

            @include mobile {
              margin: 0 10px;
              font-size: 10px;
              padding: 5px;
              height: 25px;
            }

            &:hover {
              transform: scale(1.1);
            }

            .icon{
              margin-bottom: 10px;
               @include mobile {
                 display: none;
               }
            }
          }
        }
        
      }
    }
  }
}