@import "../../global.scss";

.portfolioContainer {
  background-color: $C-4;
  display: flex;
  align-items: center;
  justify-content: center;

  .portfolio {
    height: 100%;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include medium {
      width: 100%;
    }

    h1 {
      font-size: 50px;
      color: $C-1;

      @include medium {
        font-size: 35px;
        overflow: hidden;
      }

      @include mobile {
        font-size: 20px;
      }
    }
    ul {
      padding: 0;
      margin: 10px;
      list-style: none;
      display: flex;

      @include mobile {
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    
    .container {
      width: 90%;
      height: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      @include medium {
        height: 100%;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
}