@import "../../global.scss";

.projects {
  background-color: $C-1;
  display: flex;
  align-items: center;
  justify-content: center;

  .slider {
    height: 90%;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 0.5s ease-out;

    @include mobile {
      height: 100%;
      
      justify-content: center;
    }
  }

  .next {
    position: absolute;
    right: 60px;
    font-size: 60px;
    color: $C-5;
    cursor: pointer;
    @include medium {
      right: 25px;
    }
    @include mobile{
      display: none;
    }
  }
  .back {
    position: absolute;
    left: 60px;
    font-size: 60px;
    color: $C-5;
    cursor: pointer;

    @include medium {
      left: 25px;
    }
    @include mobile {
      display: none;
    }
  }
}