@import "../../global.scss";

.contact {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $C-4;

   
    .left{
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include mobile {
        display: none;
      }
      img {
        height: 80%;
        float: right;
        overflow: hidden;
      }
    }

    .right {
      height: 100%;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 40px;
        color: $C-1;
        margin-bottom: 40px;

        @include mobile {
          font-size: 30px;
        }

        &.submitted {
          display: none;
        }
      }

      h3 {
        margin: 0;
        color: $C-1;
        opacity: 0;
        font-size: 30px;
        width: 60%;

        &.submitted {
          opacity: 1;
        }
      }

      form {
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        input {
          width: 300px;
          height: 30px;
          font-size: 14px;
          margin-bottom: 20px;
          border-radius: 10px;
          padding: 10px;
          border: 2px solid $C-1;

          @include mobile {
            width: 200px;
          }

        }

        textarea {
          width: 300px;
          height: 200px;
          font-size: 14px;
          margin-bottom: 30px;
          border-radius: 10px;
          padding: 10px;
          border: 2px solid $C-1;

          @include mobile {
            width: 200px;
          }

        }

        button {
          background-color: $C-1;
          font-size: 15px;
          width: 150px;
          height: 30px;
          font-weight: 500;
          border-radius: 10px;
          color: $C-4;
          border: none;
          cursor: pointer; 

          &:focus {
            outline: none;
          }

         
        }
        &.submitted {
          display: none;
        }
      }
    }
  }
